.article-container::-webkit-scrollbar {
  display: none;
}

.article-container {
  position: fixed;
  top: 75px;
  height: 88vh;
  width: 94%;
  right: -94%;
  padding-top: 40px;
  padding-right: 1em;
  padding-left: 1em;
  padding-bottom: 10px;
  z-index: 1;
  display: block;

  border-radius: 15px;

  text-align: justify;
}

/* .article-expanded {
  width: 94% !important;
} */

.no-bs-border,
.no-bs-border:focus,
.no-bs-border:active {
  outline: none;
  border: none;
  box-shadow: none;
  font-size: medium;
}

.custom-btn {
  margin-bottom: 15px;
  margin-left: 3px;
}
.outer-article {
  height: 100%;
  padding-right: 15px;
  margin-top: 10px;
  overflow: auto;
}

.collapsed-width {
  right: -94%;
}

.open-width {
  right: 3.5%;
  transition: 0.5s;
}

@media only screen and (min-width: 1200px) {
  .article-container {
    width: 25%;
  }
  .open-width {
    right: 3%;
    transition: 0.5s;
  }
  .collapsed-width {
    right: -25%;
  }

  /* .article-expanded {
    width: 94% !important;
  } */
}

@media only screen and (min-width: 600px) {
  .article-container {
    width: 25%;
  }
  .open-width {
    right: 3%;
    transition: 0.5s;
  }
  .collapsed-width {
    right: -25%;
  }

  /* .article-expanded {
    width: 94% !important;
  } */
}

.article-title {
  font-size: large;
  font-weight: bold;
  text-align: center;
}

.article-body {
  font-size: small;
  width: 100%;
  height: 40vh;
  margin-left: 20px;
  overflow-x: hidden;
  overflow-x: auto;
  text-align: justify;
  padding-right: 20px;
}

.article-close {
  top: 10px;
  left: 5%;
  font-size: xx-large !important;
  z-index: 10;
  position: absolute;
}

.article-expand {
  top: 10px;
  right: 5%;
  font-size: xx-large !important;
  z-index: 10;
  position: absolute;
}

.body-container {
  overflow: hidden;
}

.cover-up {
  padding-right: 100px;
}

.text-body {
  font-size: small;
  width: 100%;
  text-indent: 15px;
  text-align: left;
}

.author {
  font-size: small;
}

.bold {
  font-weight: bold;
}

.dark-card-bg-color {
  background-color: #1e1e1e;
}

.dark-font-color {
  color: #e1e1e1 !important;
}

.dark-font-color-hover {
  color: #e1e1e1 !important;
}
.dark-font-color-hover:hover {
  color: #e9e9e9 !important;
}

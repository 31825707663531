.custom-nav {
  /* height: 60px; */
  /* background-color: #1e1e1e; */
}

.nav-logo {
  position: relative;
  width: 50px;
  margin-right: 25px;

  /* padding-right: 1px; */
  /* margin-left: 100%; */
  /* padding-right: 2%; */
}

@media only screen and (max-width: 600px) {
  .nav-logo {
    /* position: relative; */
    width: 35px;
  }
  .navbar-nav {
    /* position: relative;
     */
    /* position: initial; */
  }
}

.nav-container {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 3;
}

.custom-dropdown {
  /* background-color: #1e1e1e;
  padding-right: 5%; */
  /* padding-left: 1.25em; */
  /* padding-right: 1.25em; */
  /* margin-bottom: 5%; */
  /* position: relative; */
  /* bottom: 5px; */
  /* float: right; */
}

.dropdown-menu {
  background-color: #1e1e1e !important;
}

.no-bs-border,
.no-bs-border:focus,
.no-bs-border:active {
  outline: none;
  border: none;
  box-shadow: none;
  font-size: medium;
}

.dropdown-item {
  color: #e1e1e1 !important;
}

.dropdown-toggle {
  font-size: medium;
}

/* .dropdown-item,
.dropdown-item:focus,
.dropdown-item:active {
  color: #e1e1e1 !important;
  outline: none;
  border: none;
  box-shadow: none;
  font-size: medium;
} */

.dropdown-item:hover {
  background-color: #121212 !important;
}

/* .dropdown-toggle,
.dropdown-toggle:focus,
.dropdown-toggle:active {
  outline: none;
  border: none;
  box-shadow: none;
  font-size: medium;
} */

/* .navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active {
  outline: none;
  border: none;
  box-shadow: none;
} */

.navbar-nav {
  /* align-items: right; */
  /* text-align: right; */
  /* position: fixed; */
  /* float: right; */
  /* right: 5%; */
}
.navbar-collapse {
  /* padding-top: 10px; */
  /* float: right; */
  position: relative;
  right: 0;
  /* padding-right: 100px; */
  /* align-items: right !important; */
}

.custom-navbar-brand {
  /* margin-left: 5%; */
  color: #e1e1e1 !important;
}

.custom-navbar-brand:hover {
  color: #9e9e9e !important;
}
